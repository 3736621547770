import { PrismicRichText } from "@prismicio/react";
import cn from "clsx";
import Image from "next/image";
import style from "./BlocManger.module.scss";

const BlocManger = ({ slice }) => (
  <section>
    <div className={style.manger}>
      <div className={cn("icon", style.iconHead)} />
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className={style.head}>
            <PrismicRichText field={slice.primary.title} />
          </div>
          <div className={cn("content", style.content)}>
            <div className="lk-grid twoCol space-between">
              {slice?.items?.map((item, i) => (
                <div className={style.item} key={i}>
                  <div className={style.icon}>
                    <Image
                      src={item.icon.url}
                      alt={item.icon.alt}
                      title={item.icon.alt}
                      width="69"
                      height="75"
                    />
                  </div>
                  <div className={style.label}>
                    <PrismicRichText field={item.titre} />
                  </div>
                  <div className={style.text}>
                    <PrismicRichText field={item.text} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="only_mobile">
            <Image
              src={slice.primary.image_mobile.url}
              alt={slice.primary.image_mobile.alt}
              title={slice.primary.image_mobile.alt}
              width="640"
              height="2042"
            />
          </div>
        </div>
      </div>
    </div>
    <style jsx>{`
      .icon {
        background-image: url("${slice.primary.icon.url}");
      }
      .content {
        background-image: url("${slice.primary.background_pc.url}");
      }
    `}</style>
  </section>
);

export default BlocManger;
