// Code generated by Slice Machine. DO NOT EDIT.

import AvisClients from './AvisClients';
import BarPromotion from './BarPromotion';
import BilanQuestion from './BilanQuestion';
import BilanResults from './BilanResults';
import BlocAssiette from './BlocAssiette';
import BlocManger from './BlocManger';
import BlocParrainage from './BlocParrainage';
import BlockMealsCms from './BlockMealsCms';
import BlockParrainage from './BlockParrainage';
import BlockPlatsFrais from './BlockPlatsFrais';
import BlogInfluenceurs from './BlogInfluenceurs';
import ContentSimple from './ContentSimple';
import DailyEcologie from './DailyEcologie';
import DailyHow from './DailyHow';
import DiscoverCustomerAccount from './DiscoverCustomerAccount';
import Faq from './Faq';
import FaqHead from './FaqHead';
import Footer from './Footer';
import GroupBundleInfo from './GroupBundleInfo';
import GroupHeader from './GroupHeader';
import HomeHead from './HomeHead';
import InKitchend from './InKitchend';
import MangezMieux from './MangezMieux';
import OffrePreferentielle from './OffrePreferentielle';
import Partners from './Partners';
import SliderRepas from './SliderRepas';
import SuccessReferrals from './SuccessReferrals';
import TitrePage from './TitrePage';
import VideoMauro from './VideoMauro';

export {
	AvisClients,
	BarPromotion,
	BilanQuestion,
	BilanResults,
	BlocAssiette,
	BlocManger,
	BlocParrainage,
	BlockMealsCms,
	BlockParrainage,
	BlockPlatsFrais,
	BlogInfluenceurs,
	ContentSimple,
	DailyEcologie,
	DailyHow,
	DiscoverCustomerAccount,
	Faq,
	FaqHead,
	Footer,
	GroupBundleInfo,
	GroupHeader,
	HomeHead,
	InKitchend,
	MangezMieux,
	OffrePreferentielle,
	Partners,
	SliderRepas,
	SuccessReferrals,
	TitrePage,
	VideoMauro,
};

export const components = {
	avis_clients: AvisClients,
	bar_promotion: BarPromotion,
	bilan_question: BilanQuestion,
	bilan_results: BilanResults,
	bloc_assiette: BlocAssiette,
	bloc_manger: BlocManger,
	bloc_parrainage: BlocParrainage,
	block_meals_cms: BlockMealsCms,
	block_parrainage: BlockParrainage,
	block_plats_frais: BlockPlatsFrais,
	blog_influenceurs: BlogInfluenceurs,
	content_simple: ContentSimple,
	daily_ecologie: DailyEcologie,
	daily_how: DailyHow,
	decouvre_espace_client: DiscoverCustomerAccount,
	faq: Faq,
	faq_head: FaqHead,
	footer: Footer,
	group_bundle_info: GroupBundleInfo,
	group_header: GroupHeader,
	home_head: HomeHead,
	in_kitchend: InKitchend,
	mangez_mieux: MangezMieux,
	offre_preferentielle: OffrePreferentielle,
	partners: Partners,
	slider_repas: SliderRepas,
	success_referrals: SuccessReferrals,
	titre_page: TitrePage,
	video_mauro: VideoMauro,
};
