import { PrismicRichText } from "@prismicio/react";
import Image from "next/image";
import { Link, Text } from "@components/ui";
import style from "./Blog.module.scss";

const BlogInfluenceurs = ({ slice }) => (
  <section>
    <div className={style.blog}>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-blc-title-s1 center">
            <div className="splayer">
              <PrismicRichText field={slice.primary.title} />
            </div>
          </div>
        </div>
      </div>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-flex space-between lk-wrap">
            {slice?.items?.map((item, i) => (
              <div className={style.item} key={i}>
                <div>
                  <div className={style.feat}>
                    <Image
                      src={item.feature.url}
                      alt={item.feature.alt}
                      title={item.feature.alt}
                      width="380"
                      height="334"
                    />
                  </div>
                  <div className={style.label}>
                    <Text variant="span">{item.titre}</Text>
                  </div>
                  <div className={style.text}>
                    <Text variant="p">&quot;{item.text}&quot;</Text>
                  </div>
                  <div className={style.action}>
                    <Link href={item.url.url} className="secondary">
                      {item.label}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className={style.btnBottom}>
            <Link href={slice.primary.url_instagram.url} className="secondary">
              {slice.primary.label_button}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default BlogInfluenceurs;
