import cn from "clsx";
import { Text } from "@components/ui";
import style from "@components/pages/checkout/Success/Success.module.scss";
import Image from "next/image";

const SuccessReferrals = ({ slice }) => (
  <div className={cn(style.step, style.stepSponsorship)}>
    <div className={style.top}>
      <Text variant="span">
        <strong>{slice?.primary.numero}</strong>
        {slice?.primary.titre}
      </Text>
      <Text variant="p">{slice?.primary.sous_titre}</Text>
    </div>
    <div className={style.boxParrainage}>
      <div className="lk-flex">
        <div className={cn(style.col, style.first)}>
          <Text variant="span" className={style.label}>
            {slice?.primary.label_parrain}
          </Text>
          <Text variant="span" className={style.price}>
            {slice?.primary.amount_parrain}
          </Text>
          <Text
            variant="p"
            className={style.text}
            html={slice?.primary.text_parrain}
          />
        </div>
        <div className={cn(style.col, style.second)}>
          <Text variant="span" className={style.label}>
            {slice?.primary.label_filleul}
          </Text>
          <Text variant="span" className={style.price}>
            {slice?.primary.amount_filleul}
          </Text>
          <Text
            variant="p"
            className={style.text}
            html={slice?.primary.text_filleul}
          />
        </div>
        <div className={cn(style.col, style.last)}>
          <Image
            src="/static/images/checkout/Illustration-frigo.png"
            width="256"
            height="241"
          />
        </div>
      </div>
    </div>
  </div>
);

export default SuccessReferrals;
