import cn from "clsx";
import Image from "next/image";
import { FC } from "react";
import { Logo } from "@components/commun";
import { Link, Text } from "@components/ui";
import {
  URL_PAGE_CGU,
  URL_PAGE_CGV,
  URL_PAGE_CONTACT,
  URL_PAGE_ENTREPRISE,
  URL_PAGE_FAQ,
  URL_PAGE_FORMULES,
  URL_PAGE_HISTOIRE,
} from "@middleware/constants";
import { BasicPageProps } from "@middleware/types";
import { client as env } from "@config/env/client";
import imgFacebook from "@static/images/footer/picto_facebook.png";
import imgInsta from "@static/images/footer/picto_instagram.png";
import imgYoutube from "@static/images/footer/picto_youtube.png";
import { generateDate } from "@middleware/helpers";
import { useAuth, useUI } from "@middleware/hooks";
import style from "./BottomFooter.module.scss";

export const BottomFooter: FC<BasicPageProps> = ({ translation }) => {
  const { isAuthenticated } = useAuth();
  const { openModal } = useUI();

  return (
    <>
      <div className={style.bottom} role="definition">
        <div className="lk-wrap">
          <div className={`lk-flex ${style.flex}`}>
            <div className={cn(style.col, style.collogo)}>
              <Logo type="footer" />
            </div>
            <div className={style.col}>
              <Text variant="span">{translation("links.concept")}</Text>
              <ul>
                <li>
                  <Link href={URL_PAGE_FORMULES} className="simple">
                    {translation("links.bundles")}
                  </Link>
                </li>
                <li>
                  {isAuthenticated === false && (
                    <a href="#!" onClick={openModal}>
                      {translation("links.logIn")}
                    </a>
                  )}
                </li>
                <li>
                  <Link
                    href="javascript:Didomi.preferences.show()"
                    className="simple"
                  >
                    {translation("links.cookies")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className={style.col}>
              <Text variant="span">{translation("links.discover")}</Text>
              <ul>
                <li>
                  <Link href={URL_PAGE_HISTOIRE} className="simple">
                    {translation("links.whoWeAre")}
                  </Link>
                </li>
                <li>
                  <Link href={URL_PAGE_ENTREPRISE} className="simple">
                    {translation("links.forBusinesses")}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    className="simple"
                    rel="noreferrer"
                    href={env.NEXT_PUBLIC_FACEBOOK_GROUP_URL}
                  >
                    {translation("links.groupFacebook")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className={style.col}>
              <Text variant="span">{translation("links.help")}</Text>
              <ul>
                <li>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href={URL_PAGE_FAQ}
                    className="simple"
                  >
                    {translation("links.faq")}
                  </Link>
                </li>
                <li>
                  <Link href={URL_PAGE_CONTACT} className="simple">
                    {translation("links.contact")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={style.last}>
            <div className={`lk-flex ${style.flex}`}>
              <div className={style.reseaux}>
                <ul>
                  <li>
                    <Link
                      className="simple"
                      target="_blank"
                      rel="noreferrer"
                      href={env.NEXT_PUBLIC_FACEBOOK_URL}
                    >
                      <Image
                        alt="Kitchendaily"
                        src={imgFacebook}
                        width="19"
                        height="20"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="simple"
                      target="_blank"
                      rel="noreferrer"
                      href={env.NEXT_PUBLIC_INSTAGRAM_URL}
                    >
                      <Image
                        alt="Kitchendaily"
                        src={imgInsta}
                        width="19"
                        height="20"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="simple"
                      target="_blank"
                      rel="noreferrer"
                      href={env.NEXT_PUBLIC_YOUTUBE_URL}
                    >
                      <Image
                        alt="Kitchendaily"
                        src={imgYoutube}
                        width="19"
                        height="20"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className={style.complement}>
                <Text variant="p">
                  {translation("reseaux.brand", {
                    year: generateDate().getFullYear(),
                  })}
                  <Link href={URL_PAGE_CGV} className="simple">
                    {translation("reseaux.cgv")}
                  </Link>
                  {" , "}
                  <Link href={URL_PAGE_CGU} className="simple">
                    {translation("reseaux.cgu")}
                  </Link>
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
