import cn from "clsx";
import { useEffect, useState } from "react";
import { PrismicRichText } from "@prismicio/react";
import { Link, Text } from "@components/ui";
import style from "./BarPromotion.module.scss";

const BarPromotion = ({ slice }) => {
  const [isReady, setIsReady] = useState(false);

  const handleScroll = () => {
    setIsReady(true);
  };

  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 1800);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className={cn(style.bar, { [style.isScrolled]: isReady })}>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-flex space-center">
            <div className={style.boxReduction}>
              <PrismicRichText field={slice.primary.text} />
            </div>
            <div className={style.boxAction}>
              <Link href={slice.primary.cta_url.url} className="primary">
                {slice.primary.cta_text}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BarPromotion;
