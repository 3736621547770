import { useTranslation } from "next-i18next";
import { Link, Text } from "@components/ui";
import style from "@components/pages/checkout/Success/Success.module.scss";
import Image from "next/image";
import { URL_PAGE_CUSTOMER_ORDER } from "@middleware/constants";

const DiscoverCustomerAccount = ({ slice }) => {
  const { t } = useTranslation();

  return (
    <div className={style.step}>
      <div className={style.top}>
        <Text variant="span">
          <strong>{slice?.primary.numero}</strong>
          {slice?.primary.titre}
        </Text>
        <Text variant="p">{slice?.primary.sous_titre}</Text>
      </div>
      <div className={style.feature}>
        <Image
          src={slice?.primary.image.url}
          alt={slice?.primary.image.alt}
          width="1026"
          height="341"
        />
      </div>
      <div className={style.action}>
        <Link href={URL_PAGE_CUSTOMER_ORDER} className="primary">
          {t("success.iscoverMyClientSpace")}
        </Link>
      </div>
    </div>
  );
};

export default DiscoverCustomerAccount;
