import { PrismicRichText } from "@prismicio/react";
import Image from "next/image";
import { Link } from "@components/ui";
import style from "./DailyHow.module.scss";

const DailyHow = ({ slice }) => (
  <section>
    <div className={style.who}>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-flex space-between lk-flex-mob-colomn">
            {slice?.items?.map((item, i) => (
              <div className={style.item} key={i}>
                <div className={style.label}>
                  <PrismicRichText field={item.titre} />
                </div>
                <div className={style.feat}>
                  <Image
                    src={item.feature.url}
                    alt={item.feature.alt}
                    title={item.feature.alt}
                    width="300"
                    height="275"
                  />
                </div>
                <div className={style.text}>
                  <PrismicRichText field={item.text} />
                </div>
                <div className={style.action}>
                  <Link href={item.url.url} className="secondary">
                    {item.button_label}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default DailyHow;
