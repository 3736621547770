import { Text } from "@components/ui";
import style from "./BlockParrainage.module.scss";
import { Loyalty } from "./Loyalty";

const BlockParrainage = ({ slice }) => (
  <section>
    <div className={style.parrainage}>
      <Loyalty
        imageUrl={slice.primary.image_parrain.url}
        imageAlt={slice.primary.image_parrain.alt}
        amount={slice.primary.montant_parrain}
        label={slice.primary.label_parrain}
        description={slice.primary.text_parrain}
      />
      <Loyalty
        imageUrl={slice.primary.image_filleul.url}
        imageAlt={slice.primary.image_filleul.alt}
        amount={slice.primary.montant_filleul}
        label={slice.primary.label_filleul}
        description={slice.primary.text_filleul}
      />
    </div>
    <div className={style.info}>
      <Text variant="p" html={slice.primary.text_informatif} />
    </div>
  </section>
);

export default BlockParrainage;
